@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

@font-face {
  font-family: "Sofachrome";
  src: url("/src/UI/pages/sofachrome.otf");
};


.leaflet-container {
  width: 80vw;
  height: 50vh;
  display: flex;
  place-self: center;
  justify-self: center;
  align-self: center;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */



/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/*///////////////////////////////////////// SIDEBAR STYLES - IN USE  ///////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

/* .sidebar {
  height: full-width;
  width: 200px;
  background-color: #5d50fd; */
  /* background-color: #000; */
  /* text-align: center;
  position: absolute; */
  /* left: 0;
  top: 0; */
/* } */

/* .sidebar_loggedout {
  height: 100%;
  width: 200px;
  background-color: #f3f4f7;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
}
.sidebar_list {
  padding-top: 10%;
}

.sidebar_item {
  padding: 5%;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: white;
}

.sidebar_item:hover {
  background-color: #5448e3;
  cursor: pointer;
} */

.sidebar_list #active {
  background-color: #5d50fd;
}

/* .sidebar_item_icon {
  display: grid;
  flex: 30%;
  place-items: center;
}
.sidebar_item_title {
  padding-left: 7%;
  flex: 70%;
  place-items: center;
  display: flex;
} */

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////// */






/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/*///////////////////////////////////////// GENERAL - IN USE  ///////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

/* body {
  font-family: 'Poppins', sans-serif; */
  /* height: 100vh; */
  /* width: 100%; */
  /* background-color: #5448e3; */
/* } */

/* a {
  text-decoration: none;
  color: #000;
}

p {
  line-height: 1.7;
}

ul {
  list-style: none;
}

li {
  line-height: 2.2;
}

h1,
h2,
h3 {
  font-weight: 600;
  margin-bottom: 10px;
} */

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////// */





/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/*///////////////////////////////////////// HEADER STYLES - IN USE  ///////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 60px;
}

.header ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header ul li {
  margin-left: 20px;
}

.header ul li a {
  display: flex;
  align-items: center;
}

.header ul li a:hover {
  color: #777;
}

.header ul li a svg {
  margin-right: 5px;
}

.heading {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 50px;
  padding: 0 20px;
}

.heading p {
  color: #828282;
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////// */





/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/*///////////////////////////////////////// FORM STYLES - IN USE (Register & LogIN) ///////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.form {
  width: 70%;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 10px;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: inherit;
}

.form-group label {
  text-align: left;
  display: block;
  margin: 0 0 5px 3px;
}

.btn {
  padding: 10px 20px;
  border: 1px solid #FFC500;
  border-radius: 5px;
  background: #FFC500;
  color: #383838;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn svg {
  margin-right: 8px;
}

.btn-reverse {
  background: #fff;
  color: #5d50fd;
}

.btn-block {
  width: 100%;
  margin-bottom: 20px;
}

.btn-sm {
  padding: 5px 15px;
  font-size: 13px;
}

.btn-danger {
  background: darkred;
  border: none;
}

.btn-back {
  width: 150px;
  margin-bottom: 20px;
}

.btn:hover {
  transform: scale(1.03);
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////// */




/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/*///////////////////////////////////////// SPINNER STYLES - IN USE  ///////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// */


.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////// */